<ec-shared-navigation [viewport]="viewport">
  <ec-shared-navigation-item
    icon="plus"
    [label]="'Address.new' | translate"
    class="max-w-[5rem] md:max-w-none"
    (click)="openCreationModal()"
    [viewport]="viewport"
  ></ec-shared-navigation-item>
  <ec-shared-navigation-item
    icon="bars-3-center-left"
    label="de"
    (click)="setLanguage('de')"
    [viewport]="viewport"
    >de</ec-shared-navigation-item
  >
  <ec-shared-navigation-item
    icon="bars-3-center-left"
    label="en"
    (click)="setLanguage('en')"
    [viewport]="viewport"
    >en</ec-shared-navigation-item
  >
</ec-shared-navigation>

<div class="px-14 pb-20 md:pb-0 pt-14 md:pt-4 md:ml-44 overflow-x-auto">
  <div class="form-field">
    <div class="input-masked"></div>
    <input
      [formControl]="tokenControl"
      id="authToken"
      [class.has-value]="tokenControl.value"
    />
    <label for="authToken">idToken (http, Bearer)</label>
  </div>
</div>

<ec-address-create-address-modal
  #createModal
  [language]="language"
  [authorizationToken]="tokenControl.value?.replace('Bearer ', '')"
  (errorEvent)="createErrorMessage($event)"
  [open]="createAddressModalOpen"
  (closeEvent)="createAddressModalClose($event)"
  (trackEvent)="track($event)"
>
</ec-address-create-address-modal>
